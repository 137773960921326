import Vue from "vue";
import Vuex from "vuex";
import viewer from "./modules/viewer";
import programme from "./modules/programme";
import map from "./modules/map";
import account from "./modules/account";
import resources from "./modules/resources";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    viewer,
    programme,
    map,
    resources,
    account,
  },
});
