<template
  ><div
    class="bg-gray-100  z-0 min-w-8xl max-w-8xl flex flex-col mr-2 min-h-screen"
  >
    <div
      class="h-2/4 w-full bg-gradient-to-r from-gray-600 to-gray-700 absolute z-neg-10  shadow-lg overflow-hidden"
    >
      <font-awesome-icon
        class="align-middle text-gray-700 opacity-70 absolute -top-24 right-64  "
        style="font-size:600px"
        :icon="['fa', 'list']"
        size="1x"
      />
    </div>
    <div class="flex flex-col w-full px-12 pt-20">
      <div class="bg-white rounded shadow w-full my-4 flex p-2">
        <div
          class="px-4 py-2 mt-1 flex-col border text-center rounded m-2 bg-gray-100 "
        >
          <div class="font-aquilaHeader text-lg">Viewing Period:</div>

          <div
            v-if="loading"
            class="h-6 m-auto w-72   bg-gray-300 rounded    "
          ></div>

          <div v-if="!loading" class="">
            {{ startDate | moment("ddd DD MMM YYYY") }} -
            {{ endDate | moment("ddd DD MMM YYYY") }}
          </div>
        </div>
        <div
          class="px-4 py-2 mt-1 flex-col border text-center rounded  m-2 "
          :class="activeListing('activeTasks')"
        >
          <div class="font-aquilaHeader text-lg">Viewing active tasks</div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full px-12 ">
      <div class="    ">
        <div class=" rounded      ">
          <div class=" flex  rounded flex-row   ">
            <div class=" flex-col  mr-4 rounded">
              <div class="w-64 ">
                <resource-listing
                  :dateRange="{
                    startDate: this.startDate,
                    endDate: this.endDate,
                  }"
                ></resource-listing>
              </div>
            </div>

            <gantt
              :fullListing="showAllTasks"
              :dateRange="[this.startDate, this.endDate]"
              @updateDateRange="setDates"
              @loading="setLoading"
            ></gantt>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* purgecss start ignore */
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* purgecss end ignore */
</style>
<script>
import Gantt from "../components/programme/gantt/gantt.vue";
import ResourceListing from "@/components/programme/gantt/resourceListing";

// @ is an alias to /src

export default {
  name: "Home",

  methods: {
    setDates(dates) {
      this.startDate = this.moment(dates[0]).format("YYYY-MM-DD");
      this.endDate = this.moment(dates[1]).format("YYYY-MM-DD");
      this.loading = false;
    },

    activeListing(status) {
      if (status === "activeTasks") {
        if (!this.showAllTasks) {
          return "bg-green-100";
        } else {
          return "bg-gray-100";
        }
      }

      if (status === "allTasks") {
        if (this.showAllTasks) {
          return "bg-green-100";
        } else {
          return "bg-gray-100";
        }
      }
    },
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      showAllTasks: false,
      loading: true,
    };
  },
  components: {
    Gantt,
    ResourceListing,
  },
};
</script>
