var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-1 text-white transition delay-300 duration-300 ease-in-out ",on:{"mouseover":_vm.setTooltip,"mouseleave":function($event){_vm.tooltip = false}}},[_c('div',{staticClass:"relative overflow-hidden h-6 text-sm flex bg-gray-700 rounded-xl shadow "},[_c('div',{class:'flex  ' + _vm.calcColour,style:(_vm.percentageComplete)}),_c('div',{staticClass:"absolute top-2/4 h-6 -mt-2"},[_c('div',{staticClass:"px-4 font-aquila font-bold text-shadow"},[_c('font-awesome-icon',{attrs:{"color":"white","icon":['fal', 'clock'],"size":"1x"}}),_vm._v(" "+_vm._s(_vm.title)+" - ["+_vm._s(_vm.percentage)+"% complete] ")],1)])]),_c('div',{staticClass:"flex"},[_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"relative"},[_c('div',{staticClass:"absolute z-50 w-full min-w-max p-2 -mt-1 text-sm leading-tight text-black   bg-gray-50 rounded-lg shadow-lg",style:(' top:' +
              _vm.tooltipCoordinate.y +
              'px; left:' +
              _vm.tooltipCoordinate.x +
              'px;')},[(_vm.activityData.resources != null)?_c('div',_vm._l((_vm.activityData.resources),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.resource.name)+" : "+_vm._s(item.activity)+" ")])}),0):_c('div',[_vm._v(" No resources assigned to this task ")])]),_c('svg',{staticClass:"absolute z-10 w-6 h-6 text-gray-50 transform -translate-x-12 -translate-y-3 fill-current stroke-current shadow-lg",style:(' top:' +
              _vm.tooltipCoordinate.y +
              'px; left:' +
              (_vm.tooltipCoordinate.x + 48) +
              'px;'),attrs:{"width":"8","height":"8"}},[_c('rect',{attrs:{"x":"12","y":"-10","width":"8","height":"8","transform":"rotate(45)"}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }