<script>
import { HorizontalBar } from "vue-chartjs";

export default {
  extends: HorizontalBar,

  props: ["chartdata", "options"],

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },

  methods: {
    setChartColours() {
      for (let i = 0; i < this.chartdata.datasets.length; i++) {
        this.chartdata.datasets[i].borderColor = "rgba(5, 150, 105, 1)";
        this.chartdata.datasets[i].backgroundColor = "rgba(52, 211, 153, .5)";
      }
    },
    generateRandomColours() {
      for (let i = 0; i < this.chartdata.datasets.length; i++) {
        const color = {
          r: this.randomIntFromInterval(0, 255),
          g: this.randomIntFromInterval(0, 255),
          b: this.randomIntFromInterval(0, 255),
        };

        this.chartdata.datasets[
          i
        ].borderColor = `rgba(${color.r},${color.b},${color.g},1)`;
        this.chartdata.datasets[
          i
        ].backgroundColor = `rgba(${color.r},${color.b},${color.g},0.8)`;
      }
    },

    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
};
</script>
