import axios from "axios";
import { getField, updateField } from "vuex-map-fields";

function getApiURL(path) {
  return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
  namespaced: true,
  state: {
    resources: [""],
  },

  getters: { getField },
  mutations: {
    updateField,
    setResources(state, resources) {
      state.resources = resources;
    },
  },
  actions: {
    async fetchResources({ commit }) {
      await axios.get(getApiURL(`api/resources`)).then((response) => {
        commit("setResources", response.data);
      });
    },

    async fetchResourcesActiveFromTo({ commit }, payload) {
      console.log("payload", payload);
      await axios
        .get(
          getApiURL(`api/resources`) +
            "?" +
            "active_from=" +
            payload.startDate +
            "&" +
            "active_to=" +
            payload.endDate
        )
        .then((response) => {
          commit("setResources", response.data);
        });
    },
  },
};
