<template>
  <div
    class="py-1 text-white transition delay-300 duration-300 ease-in-out "
    v-on:mouseover="setTooltip"
    v-on:mouseleave="tooltip = false"
  >
    <div
      class="relative overflow-hidden h-6 text-sm flex bg-gray-700 rounded-xl shadow "
    >
      <div :style="percentageComplete" :class="'flex  ' + calcColour"></div>

      <div class="absolute top-2/4 h-6 -mt-2">
        <div class="px-4 font-aquila font-bold text-shadow">
          <font-awesome-icon color="white" :icon="['fal', 'clock']" size="1x" />
          {{ title }} - [{{ percentage }}% complete]
        </div>
      </div>
    </div>
    <div class="flex">
      <div>
        <div class="relative" v-show="tooltip">
          <div
            class="absolute z-50 w-full min-w-max p-2 -mt-1 text-sm leading-tight text-black   bg-gray-50 rounded-lg shadow-lg"
            :style="
              ' top:' +
                tooltipCoordinate.y +
                'px; left:' +
                tooltipCoordinate.x +
                'px;'
            "
          >
            <div v-if="activityData.resources != null">
              <div v-for="(item, index) in activityData.resources" :key="index">
                {{ item.resource.name }} :
                {{ item.activity }}
              </div>
            </div>
            <div v-else>
              No resources assigned to this task
            </div>
          </div>
          <svg
            class="absolute z-10 w-6 h-6 text-gray-50 transform -translate-x-12 -translate-y-3 fill-current stroke-current shadow-lg"
            width="8"
            height="8"
            :style="
              ' top:' +
                tooltipCoordinate.y +
                'px; left:' +
                (tooltipCoordinate.x + 48) +
                'px;'
            "
          >
            <rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  props: ["title", "percentage", "activityData"],
  data() {
    return {
      tooltip: false,
      highLight: false,
      tooltipCoordinate: {},
    };
  },
  computed: {
    percentageComplete() {
      return `width: ${this.percentage}%`;
    },
    calcColour() {
      if (this.percentage <= 33) return "bg-red-500";
      if (this.percentage <= 66) return "bg-yellow-500";
      else return "bg-green-500";
    },
  },
  methods: {
    setTooltip(event) {
      this.tooltip = true;

      const bounds = event.target.getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;

      this.tooltipCoordinate = { x: x, y: y };
    },
  },
};
</script>
