<template>
  <!-- RESOURCE LISTING -->
  <div>
    <!-- SKELETON LOADER -->

    <div
      v-if="loading"
      class="bg-white border  shadow rounded-md  max-w-sm w-full mx-auto"
    >
      <div class="bg-gray-300 rounded text-center py-0.5 ">
        Unassigned Resources
      </div>
      <!-- Try get a value early on in the loading process for how many inactive resources there are, use this for the array here  -->
      <div v-for="resource in 10" :key="resource">
        <div
          class="animate-pulse flex space-x-4  bg-gray-100 m-1 p-2 rounded border border-rounded border-gray-500 "
        >
          <div class="rounded-full bg-gray-300 h-8 w-8 mb-6 mt-3"></div>
          <div class="flex-1 space-y-1 py-1">
            <div
              class="h-3 bg-gray-300 rounded"
              :class="'w-' + resource / 2 + '/6'"
            ></div>

            <div class="h-3 bg-gray-300 rounded"></div>
            <div
              v-if="resource % 3"
              class="h-3 bg-gray-300 rounded w-1/4"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- SKELETON LOADER -->
    <div v-if="!loading" class="">
      <div>
        <transition name="fade">
          <div
            v-if="dragging"
            class=" absolute left-0 top-0   w-screen   bg-black opacity-70 z-0 "
            :style="'height: ' + getHeight + 'px;'"
          ></div>
        </transition>
      </div>
      <div
        class="w-full h-full rounded bg-white shadow backdrop-filter backdrop-blur-0 z-40"
      >
        <div class="bg-gray-300 rounded text-center py-0.5 ">
          Unassigned Resources
        </div>

        <div
          @drop="onDrop($event, 1)"
          @dragenter.prevent
          @dragover.prevent
          @dragend="dragging = false"
          class=" "
          style="min-height: 38px;"
        >
          <div
            v-for="item in getList(1)"
            :key="item.id"
            draggable="true"
            @dragstart="startDrag($event, item)"
          >
            <div>
              <div
                class="bg-gray-100 m-1 p-2 rounded border border-rounded border-gray-500 hover:bg-aquila-yellowLight "
              >
                <div class="align-middle center flex">
                  <font-awesome-icon
                    class="text-gray-900  mr-4 ml-2 my-4"
                    :icon="['fal', 'user']"
                    size="1x"
                  />
                  <div class="inline-block align-middle text-xs">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="getList(1).length === 0">Nothing in this list</div>
          </div>
        </div>

        <div
          @drop="onDrop($event, 2)"
          @dragenter.prevent
          @dragover.prevent
          style="min-height: 38px;"
        >
          <div
            v-for="item in getList(2)"
            :key="item.id"
            draggable="true"
            @dragstart="startDrag($event, item)"
          >
            <font-awesome-icon
              class="text-gray-900  mx-4 my-2"
              :icon="['fal', 'user']"
              size="1x"
            />
            {{ item.title }}
          </div>
          <div v-if="getList(2).length === 0">
            <!-- Nothing in this list -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END RESOURCE LISTING -->
</template>
<style scoped>
/* purgecss start ignore */
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* purgecss end ignore */
</style>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
export default {
  name: "resourceListing",
  props: ["dateRange"],
  watch: {
    dateRange() {
      this.generateResourceList(this.dateRange);
    },
  },
  data() {
    return {
      resourceList: [],
      unsubscribe: {},
      dragging: false,
      loading: true,
    };
  },

  computed: {
    ...mapState({
      resources: (state) => state.resources.resources,
    }),

    getHeight() {
      return document.body.scrollHeight;
    },
  },

  async created() {
    this.unsubscribe = await this.$store.subscribe((/*mutation*/) => {
      // console.log(`resource listing mutation`, mutation);
    });
  },

  methods: {
    ...mapActions({
      fetchResources: "resources/fetchResources",
      fetchResourcesActiveFromTo: "resources/fetchResourcesActiveFromTo",
    }),

    ...mapMutations({}),
    getList(list) {
      return this.resourceList.filter((item) => item.list == list);
    },

    async generateResourceList(dateRange) {
      this.loading = true;
      this.resources = [];

      await this.fetchResourcesActiveFromTo({
        startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
        endDate: moment(dateRange.endDate).format("yyyy-MM-DD"),
      });
      //push the data from this.resources into this.resourceList
      for (let i = 0; i < this.resources.length; i++) {
        this.resourceList.push({
          id: this.resources[i].guid,
          title: this.resources[i].name,
          list: 1,
        });
      }

      this.loading = false;
    },

    // Drag and drop not fully implemented, left here as an example of what my plans were to do it

    startDrag(event, item) {
      this.dragging = true;
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.id);
    },

    onDrop(event, list) {
      const itemID = event.dataTransfer.getData("itemID");
      const item = this.resourceList.find((item) => item.id == itemID);
      item.list = list;
      this.dragging = false;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
