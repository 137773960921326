import axios from "axios";
import Cookies from "js-cookie";

function getApiURL(path) {
  return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
  namespaced: true,
  state: {
    token: {
      tokenType: Cookies.get("bearer") ? "bearer" : undefined,
      accessToken: Cookies.get("bearer"),
    },
    error: null,
  },
  getters: {},
  mutations: {
    setToken(state, token) {
      state.token.tokenType = token.token_type;
      state.token.accessToken = token.access_token;
    },
    setError(state, error) {
      state.error = error.data.detail;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {
    async fetchToken({ commit }, payload) {
      await axios
        .post(
          getApiURL("api/auth/token"),
          `grant_type=&username=${payload.username}&password=${payload.password}&scope=&client_id=&client_secret=`
        )
        .then((response) => {
          commit("clearError", "");

          commit("setToken", response.data);
          Cookies.set(response.data.token_type, response.data.access_token, {
            expires: 7,
          });

          axios.interceptors.request.use(function(config) {
            config.headers.common = {
              Authorization: `${response.data.token_type} ${response.data.access_token}`,
            };

            return config;
          });
        })
        .catch((error) => {
          commit("setError", error.response);
        });
    },
  },
};
