import axios from "axios";
import Vue from "vue";

function getApiURL(path) {
  return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
  namespaced: true,
  state: {
    accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN, // your access token. Needed if you using Mapbox maps
    mapStyle: process.env.VUE_APP_MAPBOX_DEFAULT_MAP_STYLE, // your map style
    zoom: 12,
    longitude: -28.228145837606758,
    latitude: 55.94581151668869,
    pitch: 20,
    bearing: 0,
    error: false,

    geoJson: {
      type: "FeatureCollection",
      features: [],
    },
  },
  getters: {},
  mutations: {
    setGeoJson(state, geoJson) {
      Vue.set(state, "geoJson", geoJson);
    },
    setLongitude(state, longitude) {
      state.longitude = longitude;
    },
    setLatitude(state, latitude) {
      state.latitude = latitude;
    },
    setMap(state, map) {
      state.map = map;
    },
    setZoom(state, zoom) {
      state.zoom = zoom;
    },
    setPitch(state, pitch) {
      state.pitch = pitch;
    },
  },
  actions: {
    async fetchGeoJson({ commit }, payload) {
      await axios
        .get(
          getApiURL(process.env.VUE_APP_MAPBOX_DEFAULT_GEOJSON_URL) +
            payload.selectedDevices +
            ".geojson" +
            "?aggregate=" +
            payload.aggregate +
            "&collectedStartDate=" +
            (payload.dateRange.length > 0
              ? payload.dateRange[0]
              : "1900-01-01") +
            "&collectedEndDate=" +
            (payload.dateRange.length > 0 ? payload.dateRange[1] : "2600-01-01")
        )
        .then((response) => {
          commit("setGeoJson", response.data);
          if (response.data.geometry) {
            commit("setLongitude", response.data.geometry.coordinates[0][0]);
            commit("setLatitude", response.data.geometry.coordinates[0][1]);
            if (payload?.zoom && payload?.zoom <= 20 && payload?.zoom >= 1)
              commit("setZoom", payload.zoom);
          }
        })
        .catch(() => {
          this.error = true;
          throw new Error("No data found for period");
        });
    },

    async fetchLatestGeoJsonAllResources({ commit }, payload) {
      await axios
        .get(
          getApiURL(process.env.VUE_APP_MAPBOX_DEFAULT_GEOJSON_URL) +
            "latest.geojson" +
            (payload?.minutes
              ? "?minutes_ago=" + payload.minutes
              : "?minutes_ago=1440")
        )
        .then((response) => {
          commit("setGeoJson", response.data);
          if (response.data.features.length > 0) {
            if (payload?.longitude) commit("setLongitude", payload.longitude);
            else
              commit(
                "setLongitude",
                response.data.features[0].geometry.coordinates[0]
              );

            if (payload?.latitude) commit("setLatitude", payload.latitude);
            else
              commit(
                "setLatitude",
                response.data.features[0].geometry.coordinates[1]
              );

            if (payload?.zoom && payload?.zoom <= 20 && payload?.zoom >= 1)
              commit("setZoom", payload.zoom);

            if (payload?.pitch) commit("setPitch", payload.pitch);
          } else {
            // else no data so lets focus on the UK or zoom to where we want to
            commit("setLongitude", -1.618145837606758);
            commit("setLatitude", 54.97581151668869);
            if (payload?.zoom && payload?.zoom <= 20 && payload?.zoom >= 1)
              commit("setZoom", payload.zoom);
            else commit("setZoom", 5);
          }
        })
        .catch((error) => {
          console.log("There was a problem...: ", error);
        });
    },
  },
};
