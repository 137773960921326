// State management for forge, not being used within aquila directly but is here as an example of a forge setup with vue and tailwind

import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

function getApiURL(path) {
  return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
  namespaced: true,
  state: {
    viewer: null, // Instance of Autodesk Viewer
    document: null, // Instance of the model
    documentLoaded: false, // Has the document been fully loaded
    viewable: null, // Link to model
    viewableGeometryGuid: null, // Link to model
    urn: null, // Link to model
    modelURL: getApiURL(process.env.VUE_APP_FORGE_MODEL_URL),
    tokenURL: getApiURL(process.env.VUE_APP_FORGE_ACCESS_TOKEN_URL),
    selectedItems: [], // List of items that have been selected (DBIds)
    lastSelectedItem: null, // Last selected item from within the viewer
    shouldCameraReposition: true, // Was viewer used to get temp data? Disable respositioning of camera
    deviceMetaData: [],
    extensions: [
      {
        id: "BIMAcademy.Event.Handler",
        label: "BIMAcademy Event Handler",
        loaded: true,
        instance: null,
        import: import("../../forge/extensions/BIMAcademy.Event.Handler.js"),
      },
      // {
      //   id: "Autodesk.Viewing.SceneBuilder",
      //   label: "SceneBuilder",
      //   loaded: false,
      //   instance: null,
      // },

      // {
      //   id: "BIMAcademy.ColourObjects",
      //   label: "Visualise Temperatures",
      //   loaded: true,
      //   instance: null,
      //   import: import("../../forge/extensions/BIMAcademy.ColourObjects.js"),
      // },
      {
        id: "BIMAcademy.Utils.GeometryQuery",
        label: "BIMAcademy [Utils] GeometryQuery",
        loaded: false,
        instance: null,
        import: import(
          "../../forge/extensions/BIMAcademy.Utils.GeometryQuery.js"
        ),
      },
      {
        id: "BIMAcademy.HTMLPoints",
        label: "BIMAcademy HTML Labels",
        loaded: true,
        instance: null,
        import: import("../../forge/extensions/BIMAcademy.HTMLPoints.js"),
      },
      // {
      //   id: "Autodesk.AEC.LevelsExtension",
      //   label: "Levels",
      //   loaded: false,
      //   instance: null,
      // },
      // {
      //   id: "Autodesk.VisualClusters",
      //   label: "VisualClusters",
      //   loaded: false,
      //   instance: null,
      // },
      // {
      //   id: "PointCloudExtension",
      //   label: "PointCloudExtension",
      //   loaded: false,
      //   instance: null,
      //   import: import("../../forge/extensions/PointCloudExtension.js"),
      // },

      {
        id: "BIMAcademy.ModelLoader",
        label: "BIMAcademy Model Loader",
        loaded: false,
        instance: null,
        import: import("../../forge/extensions/BIMAcademy.ModelLoader.js"),
      },
      // {
      //   id: "markup3d",
      //   label: "markup3d",
      //   loaded: true,
      //   instance: null,
      //   import: import("../../forge/extensions/markup3d.js"),
      // },
      // {
      //   id: "Autodesk.DiffTool",
      //   label: "DiffTool",
      //   loaded: false,
      //   instance: null,
      // },
      //{ id: "Autodesk.ViewCubeUi", label: "ViewCubeUi", loaded: true, instance: null },
      // { id: "Autodesk.Viewing.WebVR", label: "Autodesk.Viewing.WebVR", loaded: true, instance: null },
      // { id: "Autodesk.Fusion360.Animation", label: "Animation", loaded: false, instance: null },
      // { id: "Autodesk.BimWalk", label: "BimWalk", loaded: false, instance: null },
      // { id: "Autodesk.CrossFadeEffects", label: "CrossFadeEffects", loaded: false, instance: null },
      // { id: "Autodesk.DocumentBrowser", label: "DocumentBrowser", loaded: false, instance: null },
      // { id: "Autodesk.Edit2D", label: "Edit2D", loaded: false, instance: null },
      // { id: "Autodesk.Explode", label: "Explode", loaded: false, instance: null },
      // { id: "Autodesk.FullScreen", label: "FullScreen", loaded: false, instance: null },
      // { id: "Autodesk.Viewing.FusionOrbit", label: "FusionOrbit", loaded: false, instance: null },
      {
        id: "Autodesk.Geolocation",
        label: "Geolocation",
        loaded: true,
        instance: null,
      },
      // { id: "Autodesk.Viewing.ZoomWindow", label: "ZoomWindow", loaded: false, instance: null },
      // { id: "Autodesk.Viewing.Wireframes", label: "Wireframes", loaded: false, instance: null },
      // { id: "Autodesk.ViewerSettings", label: "ViewerSettings", loaded: false, instance: null },
      // { id: "Autodesk.ViewCubeUi", label: "ViewCubeUi", loaded: false, instance: null },
      // { id: "Autodesk.SplitScreen", label: "SplitScreen", loaded: false, instance: null },
      // { id: "Autodesk.Snapping", label: "Snapping", loaded: false, instance: null },
      // { id: "Autodesk.Section", label: "Section", loaded: false, instance: null },
      // { id: "Autodesk.BIM360.RollCamera", label: "RollCamera", loaded: false, instance: null },
      // { id: "Autodesk.PropertiesManager", label: "PropertiesManager", loaded: false, instance: null },
      // { id: "Autodesk.Viewing.Popout   ", label: "Popout", loaded: false, instance: null },
      // { id: "Autodesk.PDF", label: "PDF", loaded: false, instance: null },
      // { id: "Autodesk.DefaultTools.NavTools", label: "NavTools", loaded: false, instance: null },
      // { id: "Autodesk.ModelStructure", label: "ModelStructure", loaded: false, instance: null },
      // { id: "Autodesk.BIM360.Minimap", label: "Minimap", loaded: false, instance: null },
      // { id: "Autodesk.Measure", label: "Measure", loaded: false, instance: null },
      // { id: "Autodesk.LayerManager", label: "LayerManager", loaded: false, instance: null },
      // { id: "Autodesk.Hyperlink", label: "Hyperlink", loaded: false, instance: null },
      // { id: "Autodesk.GoHome", label: "GoHome", loaded: false, instance: null },
      // { id: "Autodesk.BIM360.GestureDocumentNavigation", label: "GestureDocumentNavigation", loaded: false, instance: null }
    ],
    hotspotActive: [true, true, true],
  },
  getters: {
    getField,

    getHotspotByIndex: (state) => (index) => {
      return state.hotspotActive[index];
    },

    getExtensionById: (state) => (extensionId) => {
      return state.extensions.find((extension) => extension.id === extensionId);
    },

    getExtensionByIndex: (state) => (extensionIndex) => {
      return state.extensions[extensionIndex];
    },

    loadedExtensionsId: (state) => {
      return state.extensions
        .filter((extension) => extension.loaded)
        .map((extension) => extension.id);
    },

    loadedExtensions: (state) => {
      return state.extensions.filter((extension) => extension.loaded);
    },

    getRoomTemperatureColourByDbId: (state) => (dbId) => {
      //find the device id from the dbId provided
      const deviceId = state.deviceMetaData.find((node) => node.id === dbId);
      // use the device id to find the temperature data from that device
      const deviceData = state.devices.find(
        (device) => device._id === deviceId.deviceId
      );
      // get the temperature colour from that device
      const roomColour = deviceData?.dashboard_data?.temperature?.color;

      return { roomColour: roomColour };
    },

    getRooms: (state) => {
      return { roomIds: state.deviceMetaData.map((room) => room.id) };
    },

    getDeviceMetaDataByClickable: (state) => (id) => {
      const clickable = state.deviceMetaData.find((item) =>
        item.clickables?.includes(id)
      );
      if (clickable) return clickable;
      else return state.deviceMetaData.find((item) => item.id === id);
    },
  },
  mutations: {
    updateField,

    setHotSpot(state, payload) {
      Vue.set(state.hotspotActive, payload.index, payload.state);
    },

    setDocument(state, document) {
      state.document = document;
    },

    setDocumentLoaded(state, documentLoaded) {
      state.documentLoaded = documentLoaded;
    },

    setViewer(state, viewer) {
      state.viewer = viewer;
    },

    updateExtension(state, extension) {
      Vue.set(
        state.extensions,
        state.extensions.findIndex((ext) => ext.id === extension.id),
        extension
      );
    },

    updateExtensionInstance(state, { extension, instance }) {
      const index = state.extensions.findIndex(
        (ext) => ext.id === extension.id
      );
      state.extensions[index].instance = instance;
    },

    pushSelectedItem(state, selectedItem) {
      state.selectedItems.push(selectedItem);
    },

    emptySelectedItems(state) {
      state.selectedItems = [];
    },

    updateExtensionLoaded(state, { extension, loaded }) {
      const index = state.extensions.findIndex(
        (ext) => ext.id === extension.id
      );
      state.extensions[index].loaded = loaded;
    },

    updateLastSelectedItem(state, data) {
      state.lastSelectedItem = data;
    },

    updateShouldCameraReposition(state, data) {
      state.shouldCameraReposition = data;
    },

    setViewable(state, data) {
      state.viewable = data;
    },

    setGeometryGuid(state, data) {
      state.viewableGeometryGuid =
        data.geometryGuid ||
        process.env.VUE_APP_FORGE_MODEL_GEOMETRY_GUID ||
        null;
    },

    setURN(state, data) {
      state.urn = `urn:${data.urn}`;
    },
  },
  actions: {
    async fetchViewable({ commit, state }) {
      await axios
        .get(state.modelURL)
        .then((response) => {
          commit("setViewable", response.data);
          commit("setGeometryGuid", response.data);
          commit("setURN", response.data);
        })
        .catch((reason) => {
          console.error(reason);
        });
    },
  },
};
