const ForgePlugin = {
  install(Vue) {
    if (!window.Autodesk) {
      throw new Error(
        "Forge Viewer is missing! Check it appears in the header."
      );
    } else {
      Vue.prototype.$forge = window.Autodesk;
    }
  },
};

export default ForgePlugin;
