<template>
  <div class="bg-gray-100  z-0 w-full flex flex-col  ">
    <div
      class="h-2/4 w-full bg-gradient-to-r from-gray-600 to-gray-700 absolute z-neg-10  shadow-lg overflow-hidden "
    >
      <font-awesome-icon
        class="align-middle text-gray-700 opacity-70 absolute -top-24 right-64  "
        style="font-size:600px"
        :icon="['fa', 'list']"
        size="1x"
      />
    </div>

    <div class="flex flex-col w-full px-12 pt-20 ">
      <!-- Filter section -->
      <!-- <div class="bg-white rounded shadow w-full  my-4 flex p-4">
        <button @click="listCategories">categories</button>
        <button @click="sortDataIntoCategories">sort</button>
      </div> -->
      <!-- End filter section -->

      <!-- main content -->
      <div class="bg-white rounded shadow w-full  flex-grow">
        <div>
          <table class="w-full">
            <thead>
              <tr class="border border-gray-400 border-collapse bg-gray-300 ">
                <th
                  class=" font-aquilaHeader font-normal text-xl border border-gray-400 border-collapse bg-gray-300 p-3"
                >
                  Name
                </th>
                <th
                  class=" font-aquilaHeader font-normal text-xl border border-gray-400 border-collapse bg-gray-300 p-3"
                >
                  Make
                </th>

                <th
                  class=" font-aquilaHeader font-normal text-xl border border-gray-400 border-collapse bg-gray-300 p-3"
                >
                  Current Activity
                </th>
                <th
                  class=" font-aquilaHeader font-normal text-xl border border-gray-400 border-collapse bg-gray-300 p-3"
                >
                  Status
                </th>
              </tr>
            </thead>

            <tbody v-for="row in sortedCategories" :key="row">
              <tr
                v-if="row.categoryHeader"
                class="border border-gray-400 border-collapse"
              >
                <td
                  colspan="4"
                  class="bg-gray-200 font-aquilaHeader h-10 text-lg pl-4"
                >
                  {{ row.categoryHeader }}
                </td>
              </tr>

              <tr
                v-if="!row.categoryHeader"
                class="border border-gray-400 border-collapse "
              >
                <td class="border border-gray-400 border-collapse p-2">
                  {{ row.name }}
                </td>
                <td class="border border-gray-400 border-collapse p-2">
                  {{ row.type.make }}
                </td>

                <td class="border border-gray-400 border-collapse p-2">
                  <div>
                    {{ findMatchedActivities(row) }}
                  </div>
                </td>
                <td class="p-2" v-if="row.device">
                  <div class=" text-center">
                    <font-awesome-icon
                      class="text-gray-900   "
                      :icon="['fas', 'wifi']"
                      size="1x"
                      title="Tracker operational"
                    />
                  </div>
                </td>
                <td class="p-2" v-else>
                  <div class="text-center">
                    <font-awesome-icon
                      class="text-gray-900  mr-2"
                      :icon="['fas', 'wifi-slash']"
                      size="1x"
                      title="No tracker detected"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- end main content -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "Resource",
  computed: {
    ...mapState({
      activities: (state) => state.programme.activities,
      resources: (state) => state.resources.resources,
    }),
  },
  async mounted() {
    await this.bindActvitiesToResources();
    this.listCategories();
    this.sortDataIntoCategories();
  },
  data() {
    return {
      matchedActivities: [],
      categories: [],
      sortedCategories: [],
    };
  },
  components: {},
  methods: {
    listCategories() {
      let categoriesToProcess = [];
      for (let i = 0; i < this.resources.length; i++) {
        categoriesToProcess.push(this.resources[i].type.category);
      }

      this.categories = categoriesToProcess.filter((value, index, self) => {
        return self.indexOf(value) == index;
      });
    },

    sortDataIntoCategories() {
      for (let category in this.categories) {
        this.sortedCategories.push({
          categoryHeader: this.capitalizeFirstLetter(this.categories[category]),
        });
        for (let resource in this.resources) {
          if (
            this.resources[resource].type.category === this.categories[category]
          ) {
            this.sortedCategories.push(this.resources[resource]);
          }
        }
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async bindActvitiesToResources() {
      for (let activity in this.activities) {
        if (this.activities[activity]?.resources) {
          for (let resource in this.activities[activity].resources) {
            this.matchedActivities.push(
              this.activities[activity].resources[resource]
            );
          }
        }
      }
    },
    findMatchedActivities(resource) {
      for (let activityResource in this.matchedActivities) {
        if (
          this.matchedActivities[activityResource].resource.guid ===
          resource.guid
        ) {
          return this.matchedActivities[activityResource].activity;
        }
      }

      return "Not working on any activities";
    },
  },
};
</script>
