import axios from "axios";
import { getField, updateField } from "vuex-map-fields";

function getApiURL(path) {
  return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
  namespaced: true,
  state: {
    projects: [],
    activities: [], // all activity data
    selectedItem: [],
    selectedProject: "",
    fullListing: false, // This is obsolete but i have not had time to go through and unlink it from the pages that use it
  },

  getters: { getField },
  mutations: {
    updateField,
    setProjects(state, projects) {
      state.projects = projects;
    },
    setSelectedProject(state, project) {
      state.selectedProject = project;
    },
    setActivities(state, activities) {
      state.activities = activities;
    },
    setSelectedItem(state, selectedItem) {
      state.selectedItem.push(selectedItem);
    },
    setFullListing(state, fullListing) {
      state.fullListing = fullListing;
    },
  },
  actions: {
    async fetchActivitiesByGuid({ commit }, guid) {
      await axios
        .get(
          getApiURL(
            `api/projects/${this.state.programme.selectedProject}/tasks/${guid}`
          )
        )
        .then((response) => {
          commit("setActivities", response.data);
        });
    },

    async fetchActivities({ commit }) {
      await axios
        .get(
          getApiURL(
            `api/projects/${this.state.programme.selectedProject}/tasks${
              this.state.programme.fullListing
                ? "?active_at=all"
                : "?active_at=today"
            }`
          )
        )
        .then((response) => {
          commit("setActivities", response.data);
        });
    },

    async fetchProjectListing({ commit }) {
      await axios.get(getApiURL(`api/projects`)).then((response) => {
        commit("setProjects", response.data);
      });
    },
  },
};
