<template>
  <div id="app" class="  ">
    <div class="h-screen ">
      <div id="nav" class="flex h-32 w-screen align-middle ">
        <div
          class="flex-none bg-aquila-yellow w-80 border-gray-600 border-opacity-20 border-b shadow-md"
        >
          <img
            alt="Logo"
            class="mx-16 my-8 object-center  w-44"
            src="./assets/logo.png"
          />
        </div>

        <div class="w-full ">
          <div class=" flex  mr-4">
            <div class="flex-grow m-0"></div>
            <div class=" flex py-4">
              <!-- <alert-panel></alert-panel> -->
            </div>
            <div class=" flex py-4">
              <router-link @click.native="logout" to="/logout"
                ><font-awesome-icon
                  class="text-gray-900 hover:text-aquila-yellowLight mx-4 my-2"
                  :icon="['fal', 'sign-out']"
                  size="1x"
              /></router-link>
            </div>
          </div>
          <div class="flex h-full bg-gray-50 shadow-inner  p-2 w-full  ">
            <div class=" flex w-1/2 h-10">
              <div class=" w-32 h-10  relative ">
                <div
                  class="absolute inset-0 flex items-center justify-center font-aquilaHeader"
                >
                  Select Project:
                </div>
              </div>
              <div class=" w-80 h-10  relative ">
                <t-select
                  class="flex w-80"
                  v-model="compSelectedProject"
                  :options="options"
                  text-attribute="name"
                  value-attribute="guid"
                ></t-select>
              </div>
            </div>
            <div class=" flex flex-row-reverse  w-1/2 mr-10">
              <!-- <div class="w-28 h-10  relative">
                <div
                  class="absolute inset-0 flex items-center justify-center font-aquilaHeader text-sm"
                >
                  Inactive
                </div>
                <div
                  class=" text-white absolute top-5 right-2 inline-block w-6 h-6 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full pl-2 font-bold"
                >
                  5
                </div>
              </div>

              <div class="w-28 h-10  relative">
                <div
                  class="absolute inset-0 flex items-center justify-center font-aquilaHeader text-sm "
                >
                  Active
                </div>
                <div
                  class="text-white absolute top-5 right-3 inline-block w-6 h-6 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full pl-2 font-bold"
                >
                  5
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-grow ">
        <sidebar></sidebar>

        <router-view />
      </div>
    </div>
  </div>
</template>
<style>
body {
  overflow-x: hidden;
}
</style>

<script>
import Cookies from "js-cookie";
import { mapState, mapMutations, mapActions } from "vuex";
import sidebar from "./components/sidebar/sidebar.vue";
// import AlertPanel from "./components/navigation/alerts/AlertPanel.vue";

export default {
  components: {
    sidebar,
    //AlertPanel
  },
  data() {
    return {
      options: [],
    };
  },
  watch: {
    $route(to, from) {
      this.gatherProjects(to, from);
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.account.token,
      projects: (state) => state.programme.projects,
      selectedProject: (state) => state.programme.selectedProject,
    }),

    compSelectedProject: {
      get() {
        return this.selectedProject;
      },
      set(newProject) {
        this.setSelectedProject(newProject);
        return newProject;
      },
    },
  },
  async mounted() {
    this.gatherProjects();
  },

  methods: {
    ...mapMutations({
      setToken: "account/setToken",
      setSelectedProject: "programme/setSelectedProject",
    }),

    ...mapActions({ fetchProjectListing: "programme/fetchProjectListing" }),

    async gatherProjects() {
      if (this.options.length === 0) {
        await this.fetchProjectListing();
        this.options = await this.projects;
        await this.setSelectedProject(this.options[0].guid);
      }
    },
    async logout() {
      await this.setToken({ tokenType: null, accessToken: null });
      Cookies.remove("bearer");
      this.$router.push("/login");
    },
  },
};
</script>
