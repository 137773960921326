import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import axios from "axios";
import router from "./router";
import "@/assets/css/tailwind.css";
import vuetailwindsettings from "../vue-tailwind.config.js";

import ForgePlugin from "./forge/ForgePlugin.js";
import Cookies from "js-cookie";

import VueTailwind from "vue-tailwind"; //Custom load only the components you select below
//import VueTailwind from 'vue-tailwind/dist/full' // Loads the entire library
import moment from "moment";
// import dayjs from "dayjs";
import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

import Dayjs from "vue-dayjs";

library.add(fas);
library.add(fal);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.moment = moment;

// Hellas Inspired 0auth token with refresh token

// if (Cookies.get("Bearer")) {
//   axios.interceptors.request.use(function(config) {
//     config.headers.common = {
//       Authorization: `Bearer ${Cookies.get("Bearer")}`,
//     };

//     return config;
//   });
// }

// axios.interceptors.response.use(
//   function(response) {
//     return response;
//   },
//   async function(error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       // unauthed
//       originalRequest._retry = true;

//       await store.dispatch(
//         "account/fetchTokenFromRefreshToken",
//         `${Cookies.get("refreshToken")}`
//       );
//       axios.interceptors.request.use(function(config) {
//         config.headers.common = {
//           Authorization: `Bearer ${Cookies.get("Bearer")}`,
//         };

//         // config.headers["x-csrftoken"] = document.querySelector(
//         //     'input[name="csrftoken"]'
//         // ).value;
//         return config;
//       });
//     } else {
//       if (router.currentRoute.name != "Login") router.push("/login");
//     }

//     return Promise.reject(error);
//   }
// );

if (Cookies.get("bearer")) {
  axios.interceptors.request.use(function(config) {
    config.headers.common = {
      Authorization: `Bearer ${Cookies.get("bearer")}`,
    };

    return config;
  });
}

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.log(error.response.data);
    if (error.response.status === 401) {
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;
Vue.use(VueTailwind, vuetailwindsettings);
Vue.use(ForgePlugin);
Vue.use(require("vue-moment"));
// import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
// dayjs.extend(isSameOrBefore);

Vue.use(Dayjs, {
  lang: "en",
  /**
   * addon filters { key: filter name }
   * if set {} will only dayjs base filter can use.
   */
  filters: {
    ago: "ago",
  },
  /**
   * addon directives { key: directives name }
   * set {} to disable.
   */
  directives: {
    countdown: "countdown",
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
