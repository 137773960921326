<template>
  <div class="  rounded  h-full flex-col bg-white overflow-x-scroll">
    <!-- SKELETON LOADER -->
    <div
      v-if="loading"
      class="bg-white border  shadow rounded-md   w-full mx-auto"
    >
      <table class="relative shadow m-0 p-0">
        <!-- TABLE HEADING -->
        <thead class="">
          <tr class="">
            <th
              class="min-w-zoom2 text-base  border-gray-200 bg-gray-300  border-collapse border-t border-b sticky left-0 w-full  z-30 font-normal "
            >
              Task
            </th>
            <th
              v-for="day in 10"
              :key="day"
              ref="days"
              class="text-xs  border-gray-300 bg-gray-300  border-collapse border-t border-b font-normal text-center min-w-zoom"
            >
              <div class=" w-96  ">
                <div
                  class="animate-pulse  h-4 w-64 bg-gray-400  m-auto rounded"
                ></div>
              </div>
            </th>
          </tr>
        </thead>
        <!-- END TABLE HEADING -->
        <tbody class="">
          <tr
            class="hover:bg-gray-100 border-collapse border-t border-b border-gray-300 "
            v-for="(row, index) in skeletonRandomTable"
            :key="'T' + index"
          >
            <td
              scope="row"
              class=" m-0 p-0  text-sm  font-normal text-left  sticky left-0 w-80 h-9 z-30 "
            >
              <div class="bg-white  p-2 h-full w-full ">
                <div class=" w-80 ">
                  <div
                    class="animate-pulse mb-0.5 h-3  bg-gray-400  w-5/6 rounded"
                  ></div>
                  <div
                    class="animate-pulse mt-0.5 h-3 w-2/4 bg-gray-400   rounded"
                    :class="'w-' + index / 2 + '/6'"
                  ></div>
                </div>
              </div>
            </td>

            <!-- END TASK COLUMN -->

            <td
              v-for="item in row.first"
              :key="'X' + item"
              class=" m-0 p-0  border-gray-300 bg-white border-collapse border-t border-b ml-80"
            ></td>

            <td
              :colspan="row.second"
              class="  m-0 p-0 border-gray-300  border-collapse border-t border-b bg-white "
            >
              <div :class="'absolute top-0 h-full z-40'"></div>
              <div
                class="animate-pulse mb-0.5 h-6  bg-gray-400  w-5/6 rounded-full"
              ></div>
              <div></div>
            </td>

            <td
              v-for="item in row.last"
              :key="'Y' + item"
              class=" m-0 p-0   border-gray-300 bg-white border-collapse border-t border-b "
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- SKELETON LOADER -->
    <div v-else>
      <table
        class="relative shadow m-0 p-0"
        @wheel.prevent="wheelZoom($event)"
        v-if="localActivities.length > 0"
      >
        <!-- TABLE HEADING -->
        <thead class="">
          <tr class="">
            <th
              class="min-w-zoom2 text-base  border-gray-200 bg-gray-300  border-collapse border-t border-b sticky left-0 w-full  z-30 font-normal "
            >
              Task
            </th>
            <th
              v-for="day in days"
              :key="day"
              ref="days"
              class="text-xs  border-gray-300 bg-gray-300  border-collapse border-t border-b font-normal text-center"
              :class="' min-w-zoom' + zoomLevel"
            >
              <div
                v-if="isToday(day)"
                :class="
                  'absolute top-0 h-full opacity-40 bg-green-400 min-w-zoom' +
                    zoomLevel
                "
              ></div>
              <div
                v-else
                :class="
                  'absolute top-0 h-full opacity-40 border border-gray-300 border-t border-b min-w-zoom' +
                    zoomLevel
                "
              ></div>
              {{ day | moment("dd") }}
              {{ day | moment("DD MMM") }}
            </th>
          </tr>
        </thead>
        <!-- END TABLE HEADING -->
        <tbody class="">
          <tr
            class="hover:bg-gray-100 border-collapse border-t border-b border-gray-300 "
            v-for="(task, index) in localActivities"
            :key="'T' + index"
          >
            <!--  TASK COLUMN -->
            <td
              scope="row"
              class=" m-0 p-0  text-sm  font-normal text-left  sticky left-0 w-80 h-9 z-30 "
            >
              <div class="bg-white  px-2 h-full w-full ">
                {{ task.name }}
              </div>
            </td>

            <!-- END TASK COLUMN -->

            <td
              v-for="item in calculateDaysBefore(task)"
              :key="'X' + item"
              class=" m-0 p-0  border-gray-300 bg-white border-collapse border-t border-b ml-80"
            ></td>

            <td
              :colspan="secondsToDays(task)"
              class=" cursor-pointer  m-0 p-0 border-gray-300  border-collapse border-t border-b bg-white "
              @click="showTaskManager = !showTaskManager"
            >
              <div :class="'absolute top-0 h-full z-40'"></div>

              <div>
                <!-- <TaskManager
                    :showModal="showTaskManager"
                    :task="task"
                  ></TaskManager> -->
                <progress-bar
                  :title="titleGenerator()"
                  :percentage="task.completionStatus"
                  :activityData="task"
                ></progress-bar>
              </div>
            </td>

            <td
              v-for="item in calculateDaysAfter(task)"
              :key="'Y' + item"
              class=" m-0 p-0   border-gray-300 bg-white border-collapse border-t border-b "
            ></td>
          </tr>
        </tbody>
      </table>
      <div v-else>No tasks found</div>
      <!-- creating space for tooltips -->
      <div class="h-16"></div>
      <!-- END creating space for tooltips -->
    </div>
  </div>
</template>

<style scoped>
/* purgecss ignore */
.min-w-zoom1 {
  min-width: "400px";
}
/* purgecss ignore */
.min-w-zoom2 {
  min-width: "300px";
}
/* purgecss ignore */
.min-w-zoom3 {
  min-width: "200px";
}
/* purgecss ignore */
.min-w-zoom4 {
  min-width: "100px";
}
/* purgecss ignore */
.min-w-zoom5 {
  min-width: "90px";
}
</style>

<script>
// @ is an alias to /src
import moment from "moment";
import ProgressBar from "./progressBar.vue";
// import TaskManager from "./taskmanager.vue";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  props: ["fullListing"],
  watch: {
    async fullListing() {
      await this.setFullListing(this.fullListing);
      await this.fetchActivities();
      this.localActivities = await this.activities;
      await this.calculateDateExtremities();
    },
  },
  name: "Gantt",
  components: {
    ProgressBar,
    // TaskManager,
  },
  data() {
    return {
      loading: true,
      skeletonRandomTable: [],
      zoomLevel: 1,
      localActivities: [],
      days: [],
      resourceList: [],
      showTaskManager: false,
      subscription: {},
      earliestDate: "",
      latestDate: "",
    };
  },
  computed: {
    ...mapState({
      activities: (state) => state.programme.activities,
      resources: (state) => state.resources.resources,
    }),
  },

  async mounted() {
    for (let i = 0; i < Math.floor(Math.random() * 10 + 3); i++) {
      this.skeletonRandomTable.push(this.skeletonRandomGenerator());
    }
    await this.fetchResources();
    //push the data from this.resources into this.resourceList
    for (let i = 0; i < this.resources.length; i++) {
      this.resourceList.push({
        id: this.resources[i].guid,
        title: this.resources[i].name,
        list: 1,
      });
    }
    await this.prepareGantt();
  },

  async created() {
    this.subscription = await this.$store.subscribe((mutation) => {
      if (mutation.type === "programme/setSelectedProject") {
        this.prepareGantt();
      }
    });
  },

  methods: {
    ...mapActions({
      fetchActivities: "programme/fetchActivities",
      fetchResources: "resources/fetchResources",
    }),

    ...mapMutations({
      setFullListing: "programme/setFullListing",
    }),

    skeletonRandomGenerator() {
      let random1 = Math.floor(Math.random() * 4);
      let random2 = Math.floor(Math.random() * 3 + 1);
      let end = 10 - (random1 + random2);
      return { first: random1, second: random2, last: end };
    },

    async prepareGantt() {
      await this.fetchActivities();
      this.localActivities = await this.activities;

      this.calculateDateExtremities();
      this.loading = false;
    },

    // startDrag and onDrop not implemented but i left it because
    // it will hopefully leave a clue on how i was intending to deal with dragging
    // and dropping resources to tasks

    // startDrag(event, item) {
    //   event.dataTransfer.dropEffect = "move";
    //   event.dataTransfer.effectAllowed = "move";
    //   event.dataTransfer.setData("itemID", item.id);
    // },

    // onDrop(event, list) {
    //   const itemID = event.dataTransfer.getData("itemID");
    //   const item = this.resourceList.find((item) => item.id == itemID);
    //   item.list = list;
    // },

    wheelZoom(event) {
      event.deltaY > 0
        ? this.zoomLevel >= 5
          ? ""
          : (this.zoomLevel += 1)
        : this.zoomLevel <= 1
        ? ""
        : (this.zoomLevel -= 1);
    },

    isToday(day) {
      if (
        moment(day).format("YYYY-MM-DD") ===
        moment(Date.now()).format("YYYY-MM-DD")
      )
        return true;
      else return false;
    },

    titleGenerator() {
      const num = Math.floor(Math.random() * 10);
      if (num > 4) return "Task is on time";
      if (num < 4) return "Task is behind schedule ";
      if (num > 1 && num < 3) return "Task has an issue";
    },

    dateGenerator(startDate, endDate) {
      let dates = [];
      const now = startDate.clone();
      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format("MM/DD/YYYY"));
        now.add(1, "days");
      }
      this.days = dates;
    },

    calculateDaysBefore(task) {
      return moment(task.startDate).diff(moment(this.days[0]), "days");
    },

    calculateDaysAfter(task) {
      const daysBefore = this.calculateDaysBefore(task);

      return daysBefore + this.secondsToDays(task) < this.days.length
        ? this.days.length - (daysBefore + this.secondsToDays(task))
        : 0;
    },

    secondsToDays(task) {
      const hours = task.duration / 3600;

      return hours / 24;
    },

    calculateDateExtremities() {
      let dates = [];

      for (let i = 0; i < this.localActivities.length; i++) {
        dates.push(moment(this.localActivities[i].startDate));
        dates.push(moment(this.localActivities[i].endDate));
      }

      this.earliestDate = dates.reduce(function(pre, cur) {
        return Date.parse(pre) > Date.parse(cur) ? cur : pre;
      });

      this.latestDate = dates.reduce(function(pre, cur) {
        return Date.parse(pre) < Date.parse(cur) ? cur : pre;
      });

      this.dateGenerator(this.earliestDate, this.latestDate);

      this.$emit("updateDateRange", [this.earliestDate, this.latestDate]);
    },
  },
  beforeDestroy() {
    this.subscription();
  },
};
</script>
