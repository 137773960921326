<template>
  <div
    class=" flex-shrink-0 bg-aquila-yellow w-80  m-0 font-aquilaHeader  shadow-lg  "
  >
    <ul class=" my-4 ">
      <router-link class=" text-xl" :to="{ name: 'Home' }">
        <li
          class=" text-gray-900 hover:bg-gray-600 bg-opacity-20 hover:bg-opacity-30 py-1"
          :class="$route.name === 'Home' ? 'bg-gray-500' : ''"
        >
          <font-awesome-icon
            class=" mx-4 my-2 align-middle"
            :icon="['fal', 'chart-line']"
            size="1x"
          /><span class="mt-6 align-middle">Dashboard</span>
        </li>
      </router-link>
      <router-link class="text-xl " :to="{ name: 'MapboxView' }">
        <li
          class="text-gray-900 hover:bg-gray-600 bg-opacity-20 hover:bg-opacity-30 py-1"
          :class="$route.name === 'MapboxView' ? 'bg-gray-500' : ''"
        >
          <font-awesome-icon
            class=" mx-4 my-2 align-middle"
            :icon="['fal', 'map']"
            size="1x"
          /><span class="mt-6 align-middle">Viewer</span>
        </li>
      </router-link>
      <router-link class="text-xl " :to="{ name: 'Programme' }">
        <li
          class="text-gray-900 hover:bg-gray-600 bg-opacity-20 hover:bg-opacity-30 py-1"
          :class="$route.name === 'Programme' ? 'bg-gray-500' : ''"
        >
          <font-awesome-icon
            class=" mx-4 my-2 align-middle"
            :icon="['fal', 'list']"
            size="1x"
          /><span class="mt-6 align-middle">Programme</span>
        </li>
      </router-link>
      <router-link
        class="text-xl disabled "
        :to="{ name: 'ResourceManagement' }"
      >
        <li
          class="text-gray-900 hover:bg-gray-600 bg-opacity-20 hover:bg-opacity-30 py-1 "
          :class="$route.name === 'ResourceManagement' ? 'bg-gray-500' : ''"
        >
          <font-awesome-icon
            class=" ml-4 mr-3 my-2 align-middle"
            :icon="['fal', 'users-cog']"
            size="1x"
          /><span class="mt-6 align-middle">Resource Management</span>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
