<template>
  <!-- 

    Dashboard screen


   -->

  <div class="bg-gray-100  z-0 w-full flex flex-col flex-grow  ">
    <div
      class="h-2/4 w-full bg-gradient-to-r from-gray-600 to-gray-700 absolute z-neg-10  shadow-lg overflow-hidden"
    >
      <font-awesome-icon
        class="align-middle text-gray-700 opacity-70 absolute -top-24 right-64  "
        style="font-size:600px"
        :icon="['fa', 'chart-line']"
        size="1x"
      />
    </div>

    <div class="flex w-full px-40 pt-8">
      <div class="  h-28 bg-white mx-6 rounded shadow w-4/12 pb-4 min-w-max ">
        <div class="flex-auto p-4  rounded">
          <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5
                class="text-gray-700 uppercase font-aquilaHeader font-bold text-sm"
              >
                Performance
              </h5>
              <span class="font-base font-aquila text-2xl text-gray-900">
                83.43%
              </span>
            </div>
            <div class="relative w-auto pl-4 flex-initial">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12  "
              >
                <div>
                  <font-awesome-icon
                    class="align-middle text-gray-800"
                    :icon="['fal', 'chart-bar']"
                    size="2x"
                  />
                </div>
              </div>
            </div>
          </div>
          <p class="text-sm font-aquila text-gray-400 mt-4">
            <span class="text-green-500 mr-2">
              <i>
                <font-awesome-icon
                  class="align-middle"
                  :icon="['fal', 'arrow-up']"
                  size="1x"
              /></i>
              3.48%
            </span>
            <span class="whitespace-nowrap">
              Since last month
            </span>
          </p>
        </div>
      </div>
      <div class=" mx-6  h-28 bg-white rounded shadow w-4/12 min-w-max ">
        <div class="flex-auto p-4  rounded">
          <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5
                class="text-gray-700 uppercase font-aquilaHeader font-bold text-sm"
              >
                Downtime
              </h5>
              <span class="font-base font-aquila text-2xl text-gray-900">
                21.82%
              </span>
            </div>
            <div class="relative w-auto pl-4 flex-initial">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12  "
              >
                <div>
                  <font-awesome-icon
                    class="align-middle text-gray-800"
                    :icon="['fal', 'arrow-down']"
                    size="2x"
                  />
                </div>
              </div>
            </div>
          </div>
          <p class="text-sm font-aquila text-gray-400 mt-4">
            <span class="text-red-500 mr-2">
              <i>
                <font-awesome-icon
                  class="align-middle"
                  :icon="['fal', 'arrow-down']"
                  size="1x"
              /></i>
              6.18%
            </span>
            <span class="whitespace-nowrap">
              Since last month
            </span>
          </p>
        </div>
      </div>
      <div class=" mx-6  h-28 bg-white rounded shadow w-4/12 min-w-max ">
        <div class="flex-auto p-4  rounded">
          <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5
                class="text-gray-700 uppercase font-aquilaHeader font-bold text-sm"
              >
                Events
              </h5>
              <span class="font-base font-aquila text-2xl text-gray-900">
                318
              </span>
            </div>
            <div class="relative w-auto pl-4 flex-initial">
              <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12  "
              >
                <div>
                  <font-awesome-icon
                    class="align-middle text-gray-800"
                    :icon="['fal', 'bell']"
                    size="2x"
                  />
                </div>
              </div>
            </div>
          </div>
          <p class="text-sm font-aquila text-gray-400 mt-4">
            <span class="text-red-500 mr-2">
              <i>
                <font-awesome-icon
                  class="align-middle"
                  :icon="['fal', 'arrow-down']"
                  size="1x"
              /></i>
              21.65%
            </span>
            <span class="whitespace-nowrap">
              Since last month
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="w-full ">
      <div class="flex pl-12 mr-8  mt-16">
        <div class="h-auto bg-white rounded shadow w-4/12 mb-4 ">
          <div class="text-black font-aquilaHeader text-2xl ml-6  mt-8 h-8 ">
            Project Status
          </div>
          <div class="text-black font-aquila font-light text-lg ml-6 h-12 ">
            Botley
          </div>
          <div class="text-black font-aquilaHeader text-2xl ml-6  h-8">
            End Date
          </div>
          <div class="text-black font-aquila font-light text-lg ml-6  h-12 ">
            29% complete
          </div>
          <div class=" p-4 relative ">
            <doughnut-chart :chartdata="progressData.data"></doughnut-chart>
          </div>
        </div>
        <div class=" flex  ">
          <div class="flex flex-wrap  ">
            <div class=" flex flex-grow h-auto px-4">
              <div class=" w-full    bg-white  rounded shadow py-6 mb-4 mr-4 ">
                <div class="font-aquilaHeader ml-6 text-2xl h-8 w-full">
                  Resources
                </div>
                <div class=" p-4 relative ">
                  <doughnut-chart
                    :chartdata="resourceData.data"
                  ></doughnut-chart>
                </div>
              </div>

              <div class=" w-full   bg-white rounded shadow pt-6 mb-4 ">
                <div class="font-aquilaHeader ml-6 text-2xl  h-8 w-full">
                  Activities Tasks (Today)
                </div>
                <div class=" p-4 relative ">
                  <bar-chart :chartdata="barData.data"></bar-chart>
                </div>
              </div>
            </div>
            <div class="px-4 flex-grow">
              <div class="  w-full mr-6 bg-white rounded shadow p-6 mb-4 ">
                <div class="font-aquilaHeader text-2xl  h-12 w-full">
                  Utilisation
                </div>
                <div class=" p-1  ">
                  Total Equipment: 43
                </div>
                <div class=" p-1  ">
                  Total being utilised: 43
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Doughnut from "@/components/dashboard/charts/doughnut.vue";
import HorizontalBar from "@/components/dashboard/charts/horizontalbar.vue";

export default {
  name: "Home",
  data() {
    return {
      resourceData: {
        type: "doughnut",
        data: {
          labels: ["Total on site", "Productivity"],
          datasets: [
            {
              label: "% Completed",
              data: ["43", "8"],
              borderColor: [`rgba(255,205,0,1)`, `rgba(255,215,56,1)`],
              backgroundColor: [`rgba(255,205,0,0.6)`, `rgba(255,215,56,0.6)`],
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "% Completed",
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      barData: {
        type: "horizontalBar",
        data: {
          labels: [
            "Infill %",
            "Top Soil Strip %",
            "Compact sub-base %",
            "Landscaping %",
            "Excavate topsoil %",
            "Topsoil strip % ",
          ],
          datasets: [
            {
              label: "Percent Complete",
              data: [21.3, 53.3, 64.3, 27.6, 34.2, 54.8],
              borderColor: `rgba(255,205,0,1)`,
              backgroundColor: `rgba(255,205,0,0.6)`,
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "Temperature",
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      },
      progressData: {
        type: "doughnut",
        data: {
          labels: ["WIP (Tasks %)", "Delayed (Tasks %)", "Problem (Tasks %)"],
          datasets: [
            {
              label: "% Completed",
              data: ["43", "8", "3"],
              borderColor: [
                `rgba(255,205,0,1)`,
                `rgba(255,215,56,1)`,
                `rgba(222, 183, 27,1)`,
              ],
              backgroundColor: [
                `rgba(255,205,0,0.6)`,
                `rgba(255,215,56,0.6)`,
                `rgba(222, 183, 27,0.6)`,
              ],
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: "% Completed",
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  components: {
    "doughnut-chart": Doughnut,
    "bar-chart": HorizontalBar,
  },
};
</script>
